<template>
	<div class="container">
		<div class="flex-left-row">
			<img class="logo" src="../assets/images/logo@2x.png" alt=""/>
			<div class="title">{{ state.appName }}</div>
		</div>
		<div class="top-s-box flex-left-row">
			<div class="top-s-title flex1">{{ state.areaName }}</div>
			<div class="top-s-title flex1">优惠</div>
		</div>
		<div class="center-box" @click="openMnp">
			<img class="big-word-img" style="width: 100%;" src="../assets/images/font1@2x.png" alt="">
			<img class="normal-word" src="../assets/images/word1.png" alt="">
			<div class="phone-number">服务热线 <b>4006966111</b></div>
		</div>
		<div class="btm-box">
			<!-- <img class="text-img" src="../assets/images/sfbz.png" alt=""> -->
			<button class="btn" @click="openMnp">领券打车</button>
		</div>
	</div>
</template>

<script setup>
import {onBeforeMount, reactive, ref} from 'vue'
import {useRoute} from 'vue-router'

const route = useRoute()
const type = ref('KUAIGUI')
const state = reactive({
	// baseApi: 'http://192.168.31.128',
	baseApi: 'https://dapi.kuaiguiqx.com',
	url: '',
	appid: 'wx8f4f32089598da8e',
	appName: '快龟出行',
	query: '',
	areaName: '打车'
})

const getLatestApk = () => {
	if (type.value === 'KUAIGUI') {
		state.appid = 'wx8f4f32089598da8e'
		state.appName = '快龟出行'
		state.areaName = route.query.area || '贵阳'
	} else if (type.value === 'XIAOBAOCHE') {
		state.appid = 'wx5e08cbadd2a72459'
		state.appName = '小包车出行'
		state.areaName = route.query.area || '孟定'
	} else if (type.value === 'DUOCAI') {
		state.appid = 'wx5607e08e253f3def'
		state.appName = '多彩约车'
		state.areaName = route.query.area || '六盘水'
	}
	state.query = encodeURIComponent(`uid=2409&aid=${route.query.aid}`)
	// weixin://dl/business/?appid=wx8f4f32089598da8e}&path=pages/index/index&query=2409&env_version=release
	state.url = `weixin://dl/business/?appid=${state.appid}&path=pages/index/index&query=${state.query}&env_version=release`
	openMnp()
}

const openMnp = () => {
	if (state.url) {
		window.location.href = state.url
	}
}

onBeforeMount(() => {
	if (route.query.type) {
		type.value = route.query.type
	}
	getLatestApk()
})
</script>

<style>
@import '//at.alicdn.com/t/font_2935912_ancag0zauqh.css';

* {
	outline: none;
}

html, body {
	margin: 0;
	padding: 0;
	outline: none;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

.flex-between-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.flex-left-row {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.flex1 {
	flex: 1;
}

.container {
	width: 100%;
	height: 100vh;
	background-image: url('../assets/images/ttad-bg@2x.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	padding: 20px;
	box-sizing: border-box;
	overflow: hidden;

	.logo {
		width: 50px;
		height: 50px;
	}

	.title {
		font-size: 40px;
		color: #0052a4;
		margin-left: 10px;
		font-weight: 800;
		letter-spacing: -0.1cap;
	}

	.top-s-box {
		width: 120px;
		height: 26px;
		margin-top: 20px;
		margin-left: 100px;
		background: url('../assets/images/word-bg.png') no-repeat;
		background-size: cover;

		.top-s-title {
			color: #fff;
		}
	}

	.center-box {
		width: 100%;
		margin-top: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.big-word-img {
			width: 100%;
		}

		.normal-word {
			margin-top: 10px;
			width: 80%;
		}

		.phone-number {
			margin-top: 10px;
			font-weight: 500;
			font-size: 22px;
			color: #0C4A80;
		}
	}

	.btm-box {
		position: absolute;
		box-sizing: border-box;
		bottom: 20px;
		width: 90%;

		.text-img {
			width: 80%;
		}

		.btn {
			width: 100%;
			height: 55px;
			font-size: 20px;
			border: 0;
			background-color: #0052a4;
			color: #fff;
			border-radius: 5px;

			&:active {
				opacity: 0.8;
			}
		}
	}
}


</style>
